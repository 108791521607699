// TODO: use Theming API as part of Epic "Appliquer les bonnes pratiques AgGrid" (Shortcut ID: 25606)
@use '~ag-grid-community/styles' as ag;

@include ag.grid-styles(
    (
        theme: material,
        material-primary-color: var(--color-primary),
        material-accent-color: var(--color-primary),
        font-family: (
            'Poppins',
            sans-serif,
        ),
        font-size: 12px,
        cell-horizontal-padding: 14px,
        --ag-checkbox-checked-color: var(--color-primary),
        --ag-chip-background-color: #fff,
    )
);

.ag-theme-material {
    .ag-theme-material {
        font-size: 12px;
    }

    .ag-header .ag-header-cell-text,
    .ag-header .ag-header-group-text {
        text-transform: uppercase;
        font-size: 12px;
        color: black;
    }

    .ag-react-container {
        width: 100%;
    }

    .ag-column-drop-horizontal {
        background-color: var(--color-primary-light);
    }

    .ag-icon {
        color: var(--color-primary);
        font-weight: bold;
    }

    .ag-icon-tree-closed,
    .ag-icon-tree-open {
        font-weight: 1000;
    }

    .ag-side-button-label {
        font-size: 14px;
    }

    .ag-column-panel {
        border: 2px solid #c8c8c8;
    }

    .ag-column-drop-cell {
        border: 2px solid #c8c8c8;
    }
    // TODO: move this to specific grid that need it as part of Epic "Appliquer les bonnes pratiques AgGrid" (Shortcut ID: 25606)
    .ag-header-group-cell.ag-header-group-cell-with-group
        > .ag-header-cell-comp-wrapper {
        display: flex;
        justify-content: center;
    }

    .ag-column-select {
        border-right: 2px solid #c8c8c8;
    }

    .ag-column-select-header {
        border-bottom: 2px solid #c8c8c8;
        background-color: white;
    }

    .ag-column-drop-vertical-cell-text {
        // We set 500px so that it takes all the space of the table
        width: 100px;
        overflow-wrap: break-word;
        white-space: pre-line;
    }

    .ag-column-drop-cell {
        height: 36px;
    }

    .ag-cell-label-container {
        padding: 0px;
    }
}
